<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rounds"
      theme="nocturnal"
     
    >
       <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'actions'" v-on:click="summary(props.row.id)">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="text-body cursor-pointer align-middle mr-25"
            />
        </span>
        <span v-else-if="props.column.field === 'card_price'">
            {{ props.formattedRow[props.column.field] | toCurrency}}
        </span><span v-else-if="props.column.field === 'line_prize'">
            {{ props.row.final_total_prize*10/100 | toCurrency}}
        </span>
        <span v-else-if="props.column.field === 'dline_prize'">
          {{ props.row.final_total_prize*30/100 | toCurrency}}
        </span>
        <span v-else-if="props.column.field === 'bingo_prize'">
          {{ props.row.final_total_prize*60/100 | toCurrency}}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      
    </vue-good-table>
    
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
   BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, 
    BDropdownItem,
    VueGoodTable
  },
  data () {
    return {
      columns: [
      {
        label: 'ID',
        field: 'id',
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: 'Cartelas compradas',
        field: 'total_cards_in',
      },
      {
        label: 'Jogadores',
        field: 'total_users_in',
      },
      {
        label: 'Valor cartela',
        field: 'card_price',
      },
       {
        label: 'Valor Linha',
        field: 'line_prize',
      },
       {
        label: 'Valor D.Linha',
        field: 'dline_prize',
      },
       {
        label: 'Valor Bingo',
        field: 'bingo_prize',
      },
      {
        label: 'Ações',
        field: 'actions',
      }
      ],
      rounds: [],
    }
  },
  created() {
     this.getRounds();
  },
  methods: {
    getRounds(){
      this.$http.get('/room/get-finished-rounds')
     .then(res => {
       this.rounds = res.data
       
      })
    },
    summary(id){
      this.$router.push('/round-summary/' + id);
    }
  },
}
</script>

<style>

</style>
